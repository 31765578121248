/* eslint-disable no-underscore-dangle */
import jwtDecode from 'jwt-decode'
import { isArray, isNil } from 'lodash-es'

function getSearchQueryParams(data) {
  // Strip undefined values
  return new URLSearchParams(
    Object.entries(data).reduce((agg, [k, v]) => {
      if (isNil(v)) return agg
      agg[k] = v
      return agg
    }, {})
  )
}

class APIClient {
  constructor(baseURL, opts = {}) {
    this.baseURL = baseURL
    this.options = opts
    this._accessToken = localStorage.getItem('hithrive_user')

    if (this._accessToken) {
      const decoded = jwtDecode(this._accessToken)
      this.teamSlug = decoded.team_slug
    }
  }

  get accessToken() {
    return this._accessToken
  }

  set accessToken(token) {
    this._accessToken = token
    this.teamSlug = null

    if (!token) return

    const decoded = jwtDecode(token)
    this.teamSlug = decoded.team_slug
  }

  fileUpload({
    resource,
    body = null,
    method = 'GET',
    onProgress = null,
    headers = {},
  }) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener('progress', (e) =>
        onProgress ? onProgress(e.loaded / e.total) : null
      )

      xhr.addEventListener('load', () =>
        resolve([null, JSON.parse(xhr.responseText)])
      )
      xhr.addEventListener('error', () =>
        resolve([JSON.parse(xhr.responseText), null])
      )

      let { teamSlug } = this
      teamSlug = teamSlug ? `/${teamSlug}` : ''

      xhr.open(method, `${this.baseURL}${teamSlug}${resource}`, true)

      if (this.accessToken)
        xhr.setRequestHeader('Authorization', `Bearer ${this.accessToken}`)

      Object.entries(headers).forEach(([k, v]) => xhr.setRequestHeader(k, v))

      const formData = new FormData()
      if (body) {
        Object.entries(body).forEach(([k, v]) => formData.set(k, v))
      }

      xhr.send(formData)
    })
  }

  async fetch({ resource, body = null, method = 'GET', headers = {} }) {
    const request = {
      method: method === 'PATCH' ? method : method.toLowerCase(),
      headers: new Headers({
        'Content-type': 'application/json',
        ...headers,
      }),
      ...this.options,
    }

    if (this.accessToken)
      request.headers.set('Authorization', `Bearer ${this.accessToken}`)

    let { teamSlug } = this
    teamSlug = teamSlug ? `/${teamSlug}` : ''

    if (body !== null) request.body = JSON.stringify(body)
    const resp = await fetch(`${this.baseURL}${teamSlug}${resource}`, request)
    let err = null
    let succ = null

    if (resp.ok === false) err = await resp.json()
    else if (resp.ok === true)
      succ =
        resp.headers.get('Content-Type').indexOf('application/json') > -1
          ? await resp.json()
          : await resp.blob()

    return [err, succ]
  }
}

const { VITE_API_BASE_URL } = import.meta.env
const apiClient = new APIClient(VITE_API_BASE_URL)

// API Endpoints organized by top level route

/*
 *
 * Authentication API Routes
 * Base path: /v2
 *
 * For authenticating user with backend
 *
 */
const Authentication = {
  checkEmailSSO: (email) =>
    apiClient.fetch({
      resource: '/sso/checkSSO',
      method: 'POST',
      body: { email },
    }),
  emailPassLogin: (email, password, userId) =>
    apiClient.fetch({
      resource: '/auth/login',
      method: 'POST',
      body: { email, password, userId },
    }),
  exchangeSLT: (token) =>
    apiClient.fetch({
      resource: '/auth/exchange',
      method: 'POST',
      body: { token },
    }),
  confirmInvite: ({ userId, inviteKey }) =>
    apiClient.fetch({
      resource: '/invite/confirm',
      method: 'POST',
      body: { userId, inviteKey },
    }),
  requestPasswordReset: ({ email }) =>
    apiClient.fetch({
      resource: '/auth/requestPasswordReset',
      method: 'POST',
      body: { email },
    }),
  resetPassword: ({ userId, key, password }) =>
    apiClient.fetch({
      resource: '/auth/resetPassword',
      method: 'POST',
      body: { userId, key, password },
    }),
  requestMagicLink: ({ email }) =>
    apiClient.fetch({
      resource: '/auth/requestMagicLink',
      method: 'POST',
      body: { email },
    }),
  attemptMagicLogin: ({ userId, key }) =>
    apiClient.fetch({
      resource: '/auth/magicLogin',
      method: 'POST',
      body: { key, userId },
    }),
}

const AdminCelebrations = {
  updateSettings: (celebrationSettingId, settings) =>
    apiClient.fetch({
      resource: `/admin/celebrations/settings/${celebrationSettingId}`,
      method: 'POST',
      body: settings,
    }),
  patchSettings: ({ id, fields }) =>
    apiClient.fetch({
      resource: `/admin/celebrations/celebrationSetting`,
      method: 'PATCH',
      body: { id, fields },
    }),
  updateBirthdaySettings: (settings) =>
    apiClient.fetch({
      resource: `/admin/celebrations/birthday`,
      method: 'POST',
      body: settings,
    }),

  updateNewHireSettings: (settings) =>
    apiClient.fetch({
      resource: `/admin/celebrations/newhire`,
      method: 'POST',
      body: settings,
    }),

  getMessagePreview: (message) =>
    apiClient.fetch({
      resource: `/admin/celebrations/previewMessage`,
      method: 'POST',
      body: message,
    }),

  getGeneratedCardPreview: (message) =>
    apiClient.fetch({
      resource: `/admin/celebrations/previewMessage/card`,
      method: 'POST',
      body: message,
    }),

  updateMilestoneSettings: (settings) =>
    apiClient.fetch({
      resource: `/admin/celebrations/milestones`,
      method: 'POST',
      body: settings,
    }),

  getBirthdaySettings: () =>
    apiClient.fetch({
      resource: `/admin/celebrations/birthday`,
      method: 'GET',
    }),

  getMilestoneSettings: () =>
    apiClient.fetch({
      resource: `/admin/celebrations/milestones`,
      method: 'GET',
    }),

  getNewHireSettings: () =>
    apiClient.fetch({
      resource: `/admin/celebrations/newhire`,
      method: 'GET',
    }),

  getUpcomingCelebrations: ({ startDate, endDate }) =>
    apiClient.fetch({
      resource: `/admin/celebrations/upcoming?${new URLSearchParams({
        startDate,
        endDate,
      })}`,
      method: 'GET',
    }),
  getTeamCelebrations: (queryParams = {}) =>
    apiClient.fetch({
      resource: `/admin/celebrations?${new URLSearchParams(queryParams)}`,
      method: 'GET',
    }),
  getNumCelebrations: (queryParams = {}) =>
    apiClient.fetch({
      resource: `/admin/celebrations/numCelebrations?${new URLSearchParams(queryParams)}`,
      method: 'GET',
    }),
  getTeamCelebrationAnnouncements: (queryParams = {}) =>
    apiClient.fetch({
      resource: `/admin/celebrations/announcements?${new URLSearchParams(queryParams)}`,
      method: 'GET',
    }),
  getNumCelebrationAnnouncements: (queryParams = {}) =>
    apiClient.fetch({
      resource: `/admin/celebrations/numCelebrationAnnouncements?${new URLSearchParams(queryParams)}`,
      method: 'GET',
    }),
}

const AdminReports = {
  getReports: () =>
    apiClient.fetch({
      resource: `/admin/reports`,
      method: 'GET',
    }),
  generateReport: ({ type, startDate, endDate }) =>
    apiClient.fetch({
      resource: `/admin/reports`,
      body: {
        type,
        startDate,
        endDate,
      },
      method: 'POST',
    }),
  getDownloadURL: (reportId) =>
    apiClient.fetch({
      resource: `/admin/reports/${reportId}`,
      method: 'GET',
    }),
}

const AdminTeam = {
  getCustomFields: () =>
    apiClient.fetch({
      resource: `/admin/team/customFields`,
      method: 'GET',
    }),
  updateCustomFields: (fields) =>
    apiClient.fetch({
      resource: `/admin/team/customFields`,
      body: {
        fields,
      },
      method: 'POST',
    }),
  updateSettings: (settings) =>
    apiClient.fetch({
      resource: `/admin/team/settings`,
      body: settings,
      method: 'POST',
    }),
  getTeam: () =>
    apiClient.fetch({
      resource: `/admin/team`,
      method: 'GET',
    }),
  uploadIcon: (file) =>
    apiClient.fileUpload({
      resource: `/admin/team/icon`,
      body: { file },
      method: 'PUT',
    }),
  uploadBanner: (file) =>
    apiClient.fileUpload({
      resource: `/admin/team/banner`,
      body: { file },
      method: 'PUT',
    }),
  uploadLogo: (file) =>
    apiClient.fileUpload({
      resource: `/admin/team/logo`,
      body: { file },
      method: 'PUT',
    }),
  updateBranding: (settings) =>
    apiClient.fetch({
      resource: `/admin/team/branding`,
      body: settings,
      method: 'POST',
    }),
  uploadValueBadge: (file) =>
    apiClient.fileUpload({
      resource: `/admin/team/taggableValues/badge`,
      body: { file },
      method: 'PUT',
    }),
  updateGiftingApprovers: (approvers) =>
    apiClient.fetch({
      resource: `/admin/team/gifting/approvers`,
      body: { approvers },
      method: 'POST',
    }),
  setTaggableValuesRequired: ({ require }) =>
    apiClient.fetch({
      resource: `/admin/team/taggableValues/require`,
      body: { require },
      method: 'POST',
    }),
  addTaggableValues: (toAdd) =>
    apiClient.fetch({
      resource: `/admin/team/taggableValues`,
      body: toAdd,
      method: 'PUT',
    }),
  updateTaggableValues: (toAdd) =>
    apiClient.fetch({
      resource: `/admin/team/taggableValues`,
      body: toAdd,
      method: 'POST',
    }),
  removeTaggableValues: (toRemove) =>
    apiClient.fetch({
      resource: `/admin/team/taggableValues`,
      body: toRemove,
      method: 'DELETE',
    }),
  updateShareChannels: ({ channels, restrictToChannels }) =>
    apiClient.fetch({
      resource: `/admin/team/shareChannels`,
      body: { channels, restrictToChannels },
      method: 'POST',
    }),
  updatePointSettings: (settings) =>
    apiClient.fetch({
      resource: `/admin/team/pointSettings`,
      body: settings,
      method: 'POST',
    }),
  updateWhitelistedShareChannels: (channels) =>
    apiClient.fetch({
      resource: `/admin/team/whitelistedShareChannels`,
      body: channels,
      method: 'POST',
    }),
  getSCIMConfig: () =>
    apiClient.fetch({
      resource: `/admin/team/scim`,
      method: 'GET',
    }),
  rollSCIMToken: () =>
    apiClient.fetch({
      resource: `/admin/team/scim/rollAuth`,
      method: 'POST',
    }),
  getSAMLConfig: () =>
    apiClient.fetch({
      resource: `/admin/team/saml`,
      method: 'GET',
    }),
  updateSAMLConfig: (config) =>
    apiClient.fetch({
      resource: `/admin/team/saml`,
      method: 'PUT',
      body: config,
    }),
  rollSAMLKeys: () =>
    apiClient.fetch({
      resource: `/admin/team/saml/rollKeys`,
      method: 'POST',
    }),
  sendWelcomeMessages: () =>
    apiClient.fetch({
      resource: `/admin/team/sendWelcomeMessages`,
      method: 'POST',
    }),
  acceptTOS: () =>
    apiClient.fetch({
      resource: `/admin/team/acceptTOS`,
      method: 'POST',
    }),
  updateEnabledCatalogs: (enabledCatalogs) =>
    apiClient.fetch({
      resource: `/admin/team/enabledCatalogs`,
      method: 'POST',
      body: { enabledCatalogs },
    }),
}

const AdminIntegrations = {
  listIntegrations: (query = {}) =>
    apiClient.fetch({
      resource: `/admin/integrations?${new URLSearchParams(query)}`,
      method: 'GET',
    }),
  getIntegrationById: (id) =>
    apiClient.fetch({
      resource: `/admin/integrations/${id}`,
      method: 'GET',
    }),
  getIntegrationsByType: (type) =>
    apiClient.fetch({
      resource: `/admin/integrations/byType/${type}`,
      method: 'GET',
    }),

  configureIntegration: (data = {}) =>
    apiClient.fetch({
      resource: `/admin/integrations/configure`,
      body: data,
      method: 'POST',
    }),
  setFields: (integrationId, fields = {}) =>
    apiClient.fetch({
      resource: `/admin/integrations/${integrationId}/fields`,
      body: { fields },
      method: 'POST',
    }),
  setStatus: (integrationId, status) =>
    apiClient.fetch({
      resource: `/admin/integrations/${integrationId}/status`,
      body: { status },
      method: 'POST',
    }),
  callAction: (integrationId, action, data = {}) =>
    apiClient.fetch({
      resource: `/admin/integrations/${integrationId}/${action}`,
      body: data,
      method: 'POST',
    }),

  Slack: {
    generateInstallSession: () =>
      apiClient.fetch({
        resource: `/admin/integrations/slack/install`,
        method: 'POST',
      }),
  },
}

const AdminUsers = {
  getCustomFieldValues: () =>
    apiClient.fetch({
      resource: `/admin/users/customFieldValues`,
      method: 'GET',
    }),
  listUsers: (query = {}) =>
    apiClient.fetch({
      resource: `/admin/users?${getSearchQueryParams(query)}`,
      method: 'GET',
    }),
  getByID: (id, { include } = {}) =>
    apiClient.fetch({
      resource: `/admin/users/${id}?${getSearchQueryParams({ include })}`,
      method: 'GET',
    }),
  getByIDs: (ids, query = {}) =>
    apiClient.fetch({
      resource: `/admin/users/byIDs?${new URLSearchParams(query)}`,
      method: 'POST',
      body: { ids },
    }),
  updateUser: (userId, data) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}`,
      method: 'POST',
      body: data,
    }),
  updateUserRoles: (userId, roles) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/roles`,
      method: 'POST',
      body: { roles },
    }),
  adjustRedeemablePoints: ({ userId, action, amount }) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/redeemablePoints`,
      method: 'POST',
      body: { action, amount },
    }),
  adjustAllowance: ({ userId, action, amount, idempotentId }) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/allowance`,
      method: 'POST',
      body: { action, amount, idempotentId },
    }),
  updateAllowanceOverride: (userId, body) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/allowanceOverride`,
      method: 'POST',
      body,
    }),
  updateProfilePicture: (userId, file) =>
    apiClient.fileUpload({
      resource: `/admin/users/${userId}/profilePicture`,
      body: { file },
      method: 'PUT',
    }),
  updateUserSegments: (userId, segments) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/segments`,
      body: { segments },
      method: 'POST',
    }),
  importUsers: (file, { addToSegments, fieldMap }) =>
    apiClient.fileUpload({
      resource: `/admin/users/import`,
      body: { file, addToSegments, fieldMap },
      method: 'POST',
    }),
  getJobByID: (jobId) =>
    apiClient.fetch({
      resource: `/admin/users/import/jobs/${jobId}`,
      method: 'GET',
    }),
  resendInviteEmail: (userId) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/resendInvite`,
      method: 'POST',
    }),
  addUser: (user) =>
    apiClient.fetch({
      resource: `/admin/users`,
      method: 'POST',
      body: user,
    }),
  setDates: (userId, dates) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/dates`,
      method: 'POST',
      body: dates,
    }),
  userActivity: ({ userId, cursor }) =>
    apiClient.fetch({
      resource: `/admin/users/${userId}/activity/${cursor}`,
      method: 'GET',
    }),
  setUserCelebrationPrivacy: ({ id, settings }) =>
    apiClient.fetch({
      resource: `/users/${id}/celebrationSettings`,
      method: 'POST',
      body: settings,
    }),
}

const AdminUserSegments = {
  listSegments: (query = {}) =>
    apiClient.fetch({
      resource: `/admin/userSegments?${new URLSearchParams(query)}`,
      method: 'GET',
    }),

  updateSegment: (segment) =>
    apiClient.fetch({
      resource: `/admin/userSegments/${segment.id}`,
      method: 'POST',
      body: segment,
    }),
  createSegment: (segment) =>
    apiClient.fetch({
      resource: `/admin/userSegments`,
      method: 'POST',
      body: segment,
    }),
  deleteSegment: (segment) =>
    apiClient.fetch({
      resource: `/admin/userSegments/${segment.id}`,
      method: 'DELETE',
    }),
}

const AdminChallenges = {
  listChallenges: () =>
    apiClient.fetch({
      resource: `/admin/challenges`,
      method: 'GET',
    }),
  createChallenge: (challenge) =>
    apiClient.fetch({
      resource: `/admin/challenges`,
      method: 'POST',
      body: challenge,
    }),
  shareChallenge: ({ challengeId, description, title, shareTo }) =>
    apiClient.fetch({
      resource: `/admin/challenges/${challengeId}/share`,
      method: 'POST',
      body: { title, description, shareTo },
    }),
  getByID: (id) =>
    apiClient.fetch({
      resource: `/admin/challenges/${id}`,
      method: 'GET',
    }),
  getClaimsByChallenge: (id) =>
    apiClient.fetch({
      resource: `/admin/challenges/${id}/claims`,
      method: 'GET',
    }),
  deleteChallenge: (id) =>
    apiClient.fetch({
      resource: `/admin/challenges/${id}`,
      method: 'DELETE',
    }),
  getStats: ({ recentlyEndedDayThreshold, endingSoonDayThreshold }) =>
    apiClient.fetch({
      resource: `/admin/challenges/stats?${new URLSearchParams({
        recentlyEndedDayThreshold,
        endingSoonDayThreshold,
      })}`,
    }),
  getPartipationRateById: (id) =>
    apiClient.fetch({
      resource: `/admin/challenges/${id}/participation_rate`,
      method: 'GET',
    }),
}

const AdminNominationCampaigns = {
  listCampaigns: () =>
    apiClient.fetch({
      resource: `/admin/nominations`,
      method: 'GET',
    }),
  getByID: (id) =>
    apiClient.fetch({
      resource: `/admin/nominations/campaigns/${id}`,
      method: 'GET',
    }),
  getSubmissions: (id) =>
    apiClient.fetch({
      resource: `/admin/nominations/campaigns/${id}/submissions`,
      method: 'GET',
    }),
  getStats: ({ startingSoonDayThreshold, endingSoonDayThreshold }) =>
    apiClient.fetch({
      resource: `/admin/nominations/stats?${new URLSearchParams({
        startingSoonDayThreshold,
        endingSoonDayThreshold,
      })}`,
      method: 'GET',
    }),
  createCampaign: (campaign) =>
    apiClient.fetch({
      resource: `/admin/nominations`,
      method: 'POST',
      body: campaign,
    }),
  addReminder: (body) =>
    apiClient.fetch({
      resource: `/admin/nominations/reminder`,
      method: 'POST',
      body,
    }),
  deleteReminder: (body) =>
    apiClient.fetch({
      resource: `/admin/nominations/reminder`,
      method: 'DELETE',
      body,
    }),
  editRemidner: (body) =>
    apiClient.fetch({
      resource: `/admin/nominations/reminder`,
      method: 'PUT',
      body,
    }),
  softDeleteCampaignById: (id) =>
    apiClient.fetch({
      resource: `/admin/nominations/campaigns/${id}`,
      method: 'DELETE',
    }),
}

const AdminRewards = {
  getItems: () =>
    apiClient.fetch({
      resource: `/admin/rewards/customCatalog`,
      method: 'GET',
    }),
  createCustomCatalogProduct: (product) =>
    apiClient.fetch({
      resource: `/admin/rewards/customCatalog`,
      method: 'POST',
      body: product,
    }),
  getCustomCatalogProduct: (productId) =>
    apiClient.fetch({
      resource: `/admin/rewards/customCatalog/${productId}`,
      method: 'GET',
    }),
  updateCustomCatalogProduct: (product) =>
    apiClient.fetch({
      resource: `/admin/rewards/updateCustomCatalog`,
      method: 'POST',
      body: product,
    }),
  deleteCustomCatalogProductById: (id) =>
    apiClient.fetch({
      resource: `/admin/rewards/deleteCustomCatalog`,
      method: 'POST',
      body: { id },
    }),
  uploadImage: (file) =>
    apiClient.fileUpload({
      resource: `/admin/rewards/customCatalog/image`,
      body: { file },
      method: 'PUT',
    }),
  teamRedemptions: (queryObj) =>
    apiClient.fetch({
      resource: `/admin/rewards/teamRedemptions?${new URLSearchParams(
        queryObj
      )}`,
      method: 'GET',
    }),

  updateRedemptionStatus: ({ id, status }) =>
    apiClient.fetch({
      resource: `/admin/rewards/redemptions/${id}/status`,
      method: 'POST',
      body: { status },
    }),

  setLowBalanceAlert: ({ toggle, threshold }) =>
    apiClient.fetch({
      resource: `/admin/rewards/lowBalanceAlert`,
      method: 'POST',
      body: { toggle, threshold },
    }),

  getRedemptionsBySendId: (id) =>
    apiClient.fetch({
      resource: `/admin/rewards/redemptionsBySendId?${new URLSearchParams({ sendId: id })}`,
      method: 'GET',
    }),
}

const AdminShoutout = {
  getStats: ({ startTimestampMillis, endTimestampMillis }) =>
    apiClient.fetch({
      resource: `/admin/recognition/stats/shoutout?start=${startTimestampMillis}&end=${endTimestampMillis}`,
      method: 'GET',
    }),
}

const AdminGifting = {
  getStats: ({ startTimestampMillis, endTimestampMillis }) =>
    apiClient.fetch({
      resource: `/admin/gifting/stats?start=${startTimestampMillis}&end=${endTimestampMillis}`,
      method: 'GET',
    }),
  getSendAndClaimsByCelebrationId: (id) =>
    apiClient.fetch({
      resource: `/admin/gifting/sendAndClaimsByCelebrationId?${new URLSearchParams({ celebrationId: id })}`,
      method: 'GET',
    }),
  getSpotCelebrations: (queryParams = {}) =>
    apiClient.fetch({
      resource: `/admin/gifting/sends?${new URLSearchParams(queryParams)}`,
      method: 'GET',
    }),
  numGiftsSent: () =>
    apiClient.fetch({
      resource: `/admin/gifting/numSent`,
      method: 'GET',
    }),
}

const Admin = {
  getStats: ({ startTimestampMillis, endTimestampMillis }) =>
    apiClient.fetch({
      resource: `/admin/stats/?start=${startTimestampMillis}&end=${endTimestampMillis}`,
      method: 'GET',
    }),
}

const UserSegments = {
  listSegments: () =>
    apiClient.fetch({
      resource: `/userSegments`,
      method: 'GET',
    }),
}

const User = {
  getCurrentUser: (include = 'rewards,allowance,celebrationSettings') =>
    apiClient.fetch({
      resource: `/users/me?include=${include}`,
      method: 'GET',
    }),

  getByID: (id) =>
    apiClient.fetch({
      resource: `/users/${id}`,
      method: 'GET',
    }),

  getUserGroups: (userId = 'me') =>
    apiClient.fetch({
      method: 'GET',
      resource: `/users/${userId}/groups`,
    }),

  listUsers: (query = {}) =>
    apiClient.fetch({
      resource: `/users?${new URLSearchParams(query)}`,
      method: 'GET',
    }),

  getShippingAddresses: () =>
    apiClient.fetch({
      resource: `/users/me/shippingAddresses`,
      method: 'GET',
    }),

  addShippingAddress: (address) =>
    apiClient.fetch({
      resource: `/users/me/shippingAddresses`,
      method: 'PUT',
      body: address,
    }),
  setPassword: (password) =>
    apiClient.fetch({
      resource: `/users/me/password`,
      method: 'POST',
      body: { password },
    }),
  setLocale: (locale) =>
    apiClient.fetch({
      resource: `/users/me/locale`,
      method: 'POST',
      body: { locale },
    }),
  activateAccount: () =>
    apiClient.fetch({
      resource: `/users/me/activate`,
      method: 'POST',
    }),
  getNotifications: () =>
    apiClient.fetch({
      resource: `/users/notifications`,
      method: 'GET',
    }),
  setCelebrationPrivacy: (settings) =>
    apiClient.fetch({
      resource: `/users/me/celebrationSettings`,
      method: 'POST',
      body: settings,
    }),
  enableTOTP: () =>
    apiClient.fetch({
      resource: `/users/me/enableTOTP`,
      method: 'POST',
    }),
  verifyTFA: (verify) =>
    apiClient.fetch({
      resource: `/users/me/verifyTFA`,
      method: 'POST',
      body: verify,
    }),
  getExternalRails: () =>
    apiClient.fetch({
      resource: `/users/me/rails`,
      method: 'GET',
    }),
  addExternalRail: ({ type, address }) =>
    apiClient.fetch({
      resource: `/users/me/rails`,
      method: 'PUT',
      body: { type, address },
    }),
  verifyExternalRail: ({ railId, code }) =>
    apiClient.fetch({
      resource: `/users/me/rails/${railId}/verify`,
      method: 'POST',
      body: { code },
    }),
}

const Shoutout = {
  post: ({
    senderId,
    message,
    points,
    attachments,
    privacy,
    forUsers,
    taggedValue,
    shareTo,
  }) =>
    apiClient.fetch({
      resource: `/shoutout`,
      method: 'POST',
      body: {
        senderId,
        message,
        points,
        attachments,
        privacy,
        forUsers,
        taggedValue,
        shareTo,
      },
    }),
}

const Post = {
  getReactions: ({ entityId }) =>
    apiClient.fetch({
      resource: `/posts/reactions/${entityId}`,
      method: 'GET',
    }),
  react: ({ entityId, reaction }) =>
    apiClient.fetch({
      resource: `/posts/reactions/${entityId}`,
      method: 'POST',
      body: {
        reaction,
      },
    }),
  getComments: ({ entityId, cursor, limit }) =>
    apiClient.fetch({
      resource: `/posts/comments/${entityId}?${getSearchQueryParams({
        cursor,
        limit,
      })}`,
      method: 'GET',
    }),
  comment: ({ entityId, comment }) =>
    apiClient.fetch({
      resource: `/posts/comments/${entityId}`,
      method: 'POST',
      body: {
        comment,
      },
    }),
}

const Nominations = {
  listCampaigns: () =>
    apiClient.fetch({
      resource: `/nominations/campaigns`,
      method: 'GET',
    }),
  submitNomination: ({ campaignId, userId, customFields, customFieldLabels }) =>
    apiClient.fetch({
      resource: `/nominations/campaigns/${campaignId}/nominate`,
      method: 'POST',
      body: { userId, customFields, customFieldLabels },
    }),
}

const Challenges = {
  listChallenges: () =>
    apiClient.fetch({
      resource: `/challenges`,
      method: 'GET',
    }),
  getChallengeById: (id) =>
    apiClient.fetch({
      resource: `/challenges/challenge/${id}`,
      method: 'GET',
    }),
  moderateChallengeClaim: ({ claimId, action, note }) =>
    apiClient.fetch({
      resource: `/challenges/claims/${claimId}/moderate`,
      method: 'POST',
      body: { action, note },
    }),
  listPendingApproval: () =>
    apiClient.fetch({
      resource: `/challenges/pending_approval`,
      method: 'GET',
    }),
  claimChallenge: ({ id, customFields, customFieldLabels }) =>
    apiClient.fetch({
      resource: `/challenges/${id}/claim`,
      method: 'POST',
      body: { customFields, customFieldLabels },
    }),
}

const Billing = {
  initPaymentForm: () =>
    apiClient.fetch({
      resource: `/admin/billing/paymentMethods`,
      method: 'POST',
    }),
  generateTopupInvoice: (params = {}) =>
    apiClient.fetch({
      resource: `/admin/billing/generateTopupInvoice`,
      method: 'POST',
      body: params,
    }),
  getPaymentMethods: (query = {}) =>
    apiClient.fetch({
      resource: `/admin/billing/paymentMethods?${new URLSearchParams(query)}`,
      method: 'GET',
    }),
  initInstantTopup: ({ points }) =>
    apiClient.fetch({
      resource: `/admin/billing/instantTopup`,
      method: 'POST',
      body: { points },
    }),
  makeDefault: ({ id, type }) =>
    apiClient.fetch({
      resource: `/admin/billing/paymentMethods/defaultMethod`,
      method: 'POST',
      body: { id, type },
    }),
  removePaymentMethod: ({ id }) =>
    apiClient.fetch({
      resource: `/admin/billing/paymentMethods/${id}`,
      method: 'DELETE',
    }),
  getSubscription: () =>
    apiClient.fetch({
      resource: `/admin/billing/subscription`,
      method: 'GET',
    }),
  getInvoices: ({ type } = {}) =>
    apiClient.fetch({
      resource: `/admin/billing/invoices?${new URLSearchParams({ type })}`,
      method: 'GET',
    }),
  getPaymentHistoryForTeam: (query) =>
    apiClient.fetch({
      resource: `/admin/billing/paymentHistory?${new URLSearchParams(query)}`,
      method: 'GET',
    }),
  numPaymentsForTeam: (query) =>
    apiClient.fetch({
      resource: `/admin/billing/numPaymentsMade?${new URLSearchParams(query)}`,
      method: 'GET',
    }),
}

const Rewards = {
  getRewardsSummary: (params) =>
    apiClient.fetch({
      resource: `/admin/rewards/summary${
        params
          ? `?${new URLSearchParams({
              start: params.startTimestampMillis,
              end: params.endTimestampMillis,
            })}`
          : ''
      }`,
      method: 'GET',
    }),

  makeRedemption: (redemption) =>
    apiClient.fetch({
      resource: '/rewards/redemptions',
      method: 'POST',
      body: redemption,
    }),

  GiftCards: {
    listGiftCards: (country, args = {}) =>
      apiClient.fetch({
        resource: `/giftCards/${country}?${new URLSearchParams(args)}`,
        method: 'GET',
      }),
    getGiftCardById: ({ id }) =>
      apiClient.fetch({
        resource: `/giftCards/giftCard/${id}`,
        method: 'GET',
      }),
  },

  Amazon: {
    getHomeData: ({ shippingAddress, country }) =>
      apiClient.fetch({
        resource: `/amazon?${getSearchQueryParams({ shippingAddress, country })}`,
        method: 'GET',
      }),
    search: ({ query, page, country }) =>
      apiClient.fetch({
        resource: `/amazon/search?${new URLSearchParams({
          query,
          page,
          country,
        })}`,
        method: 'GET',
      }),
    getProduct: ({ asin, country }) =>
      apiClient.fetch({
        resource: `/amazon/product/${asin}?${new URLSearchParams({
          country,
        })}`,
        method: 'GET',
      }),
    getVariant: ({ asin, shippingAddress, country }) =>
      apiClient.fetch({
        resource: `/amazon/variant/${asin}?${new URLSearchParams({
          country,
          shippingAddress,
        })}`,
        method: 'GET',
      }),
    placeOrder: (order, country) =>
      apiClient.fetch({
        resource: `/amazon/order?${new URLSearchParams({
          country,
        })}`,
        method: 'POST',
        body: order,
      }),
  },

  Charities: {
    getCharities: (eins) =>
      apiClient.fetch({
        resource: `/charities/getByEINs`,
        body: eins,
        method: 'POST',
      }),
    searchCharities: (q) =>
      apiClient.fetch({
        resource: `/charities/search?${new URLSearchParams({
          q,
        })}`,
        method: 'GET',
      }),
  },

  Marketplace: {
    getBrands: ({
      country,
      cursor,
      previewProducts = false,
      getAllBrands = false,
      tags = '',
      shippingAddress = null,
      minPrice = null,
      maxPrice = null,
    }) =>
      apiClient.fetch({
        resource: `/rewards/marketplace/brands?${getSearchQueryParams({
          country,
          cursor,
          previewProducts,
          getAllBrands,
          shippingAddress,
          tags: isArray(tags) ? tags.join(',') : tags,
          minPrice,
          maxPrice,
        })}`,
        method: 'GET',
      }),
    getItems: ({ country, cursor, shippingAddress = null }) =>
      apiClient.fetch({
        resource: `/rewards/marketplace/products?${getSearchQueryParams({
          country,
          cursor,
          shippingAddress,
        })}`,
        method: 'GET',
      }),
    getProductsByBrand: (
      brandId,
      { country, shippingAddress = null, minPrice = null, maxPrice = null }
    ) =>
      apiClient.fetch({
        resource: `/rewards/marketplace/brands/${brandId}/products?${getSearchQueryParams(
          {
            country,
            shippingAddress,
            minPrice,
            maxPrice,
          }
        )}`,
        method: 'GET',
      }),
    getProductById: (productId, { shippingAddress = null } = {}) =>
      apiClient.fetch({
        resource: `/rewards/marketplace/product/${encodeURIComponent(
          productId
        )}?${getSearchQueryParams({
          shippingAddress,
        })}`,
        method: 'GET',
      }),
  },

  CustomCatalog: {
    getItems: ({ country, returnAll = false }) =>
      apiClient.fetch({
        resource: `/rewards/customCatalog?${getSearchQueryParams({
          country,
          returnAll,
        })}`,
        method: 'GET',
      }),
    getItem: (id) =>
      apiClient.fetch({
        resource: `/rewards/customCatalog/${id}`,
        method: 'GET',
      }),
    redeemItem: (id, variations) =>
      apiClient.fetch({
        resource: `/rewards/customCatalog/redeem`,
        body: {
          id,
          variations,
        },
        method: 'POST',
      }),
  },

  Redemptions: {
    getRedemptionsForUser: () =>
      apiClient.fetch({
        resource: `/rewards/redemptions/redemptionsForUser`,
        method: 'GET',
      }),
  },
}

const Team = {
  getMetaInfo: (addlArgs) =>
    apiClient.fetch({
      resource: `/team?${new URLSearchParams(addlArgs)}`,
      method: 'GET',
    }),
  uploadFile: ({ formData, onProgress }) =>
    new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener('progress', (e) =>
        onProgress ? onProgress(e.loaded / e.total) : null
      )

      xhr.addEventListener('load', () =>
        resolve([null, JSON.parse(xhr.responseText)])
      )
      xhr.addEventListener('error', () =>
        resolve([JSON.parse(xhr.responseText), null])
      )

      xhr.open(
        'PUT',
        `https://us-central1-hithrive.cloudfunctions.net/file-service`,
        true
      )
      xhr.setRequestHeader('Authorization', `Bearer ${apiClient.accessToken}`)

      xhr.send(formData)
    }),
  deleteFile: (fileId) =>
    fetch(
      `https://us-central1-hithrive.cloudfunctions.net/file-service/${fileId}`,
      {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${apiClient.accessToken}`,
        }),
      }
    ).then(async (resp) => {
      let err = null
      let succ = null

      if (resp.ok === false) err = await resp.json()
      else if (resp.ok === true) succ = await resp.json()

      return [err, succ]
    }),
  getFile: (fileId) =>
    fetch(
      `https://us-central1-hithrive.cloudfunctions.net/file-service/${fileId}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${apiClient.accessToken}`,
        }),
      }
    ).then(async (resp) => {
      let err = null
      let succ = null

      if (resp.ok === false) err = await resp.json()
      else if (resp.ok === true) succ = await resp.blob()

      return [err, succ]
    }),
  getCardTemplates: () =>
    apiClient.fetch({
      resource: `/team/cardTemplates`,
      method: 'GET',
    }),
}

const Feed = {
  getFeed: ({ postsAfter, featuredPost }) =>
    apiClient.fetch({
      resource: `/feed?${new URLSearchParams({ postsAfter, featuredPost })}`,
      method: 'GET',
    }),
}

const Gifting = {
  send: (send) =>
    apiClient.fetch({ resource: `/gifting`, method: 'POST', body: send }),
  getClaims: () =>
    apiClient.fetch({ resource: `/gifting/claims`, method: 'GET' }),
  claimGift: ({ sendId, selectedGifts, country, user, shippingAddress }) =>
    apiClient.fetch({
      resource: `/gifting/${sendId}/claim`,
      method: 'POST',
      body: { selectedGifts, country, user, shippingAddress },
    }),
  getModerationQueue: () =>
    apiClient.fetch({ resource: `/gifting/pending_approval` }),
  moderateGiftSend: ({ sendId, action, note }) =>
    apiClient.fetch({
      resource: `/gifting/sends/${sendId}/moderate`,
      method: 'POST',
      body: { action, note },
    }),
  getGiftsSent: () =>
    apiClient.fetch({
      resource: `/gifting/sends/giftsSentByUser`,
      method: 'GET',
    }),

  createGiftingBundle: (data) =>
    apiClient.fetch({
      resource: `/gifting/bundle`,
      method: 'POST',
      body: data,
    }),
  updateGiftingBundle: (data) =>
    apiClient.fetch({
      resource: `/gifting/bundle`,
      method: 'PUT',
      body: data,
    }),
  deleteGiftingBundleById: (id) =>
    apiClient.fetch({
      resource: `/gifting/bundle`,
      method: 'DELETE',
      body: { id },
    }),
  getTeamGiftingBundles: () =>
    apiClient.fetch({
      resource: `/gifting/bundlesForTeam`,
      method: 'GET',
    }),

  getGiftClaimHistoryById: (id) =>
    apiClient.fetch({
      resource: `/gifting/sends/${id}/claimHistory`,
      method: 'GET',
    }),
  getBudgetsAvailableToUser: (userId) =>
    apiClient.fetch({
      resource: `/users/${userId}/budgetsModerated`,
      method: 'GET',
    }),
}

const App = {
  getMetadata: () => apiClient.fetch({ resource: `/metadata`, method: 'GET' }),
  stockPhotoSearch: (q = '') =>
    apiClient.fetch({
      resource: `/stockPhotos?${new URLSearchParams({ q })}`,
      method: 'GET',
    }),
}

const Notifications = {
  markNotificationsAsRead: (notificationIds) =>
    apiClient.fetch({
      resource: `/notifications/markRead`,
      method: 'POST',
      body: { ids: notificationIds },
    }),
  getUserNotifications: () =>
    apiClient.fetch({
      resource: `/notifications/user`,
      method: 'GET',
    }),
  updateUserNotificationPreferences: (notificationPreferences) =>
    apiClient.fetch({
      resource: `/notifications/userPreferences`,
      method: 'POST',
      body: { notification_preferences: notificationPreferences },
    }),
  getWeightedPreferences: () =>
    apiClient.fetch({
      resource: `/notifications/weightedPreferencesForUser`,
      method: 'GET',
    }),
}

const AdminNotifications = {
  getTeamNotifications: () =>
    apiClient.fetch({
      resource: `/notifications/team`,
      method: 'GET',
    }),
  updateTeamNotificationPreferences: (notificationPreferences) =>
    apiClient.fetch({
      resource: `/admin/notifications/teamPreferences`,
      method: 'POST',
      body: { notification_preferences: notificationPreferences },
    }),
}

export {
  App,
  Feed,
  Team,
  Gifting,
  User,
  UserSegments,
  Challenges,
  Nominations,
  Post,
  Rewards,
  Billing,
  Shoutout,
  Authentication,
  AdminCelebrations,
  AdminReports,
  AdminChallenges,
  AdminUsers,
  AdminUserSegments,
  AdminNominationCampaigns,
  AdminTeam,
  AdminIntegrations,
  AdminRewards,
  AdminShoutout,
  AdminGifting,
  Admin,
  Notifications,
  AdminNotifications,
}
export default apiClient
